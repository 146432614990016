import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
// import { Table, Button, Popup } from 'semantic-ui-react';
// import { Button, Popup } from 'semantic-ui-react';
// import { ReactComponent as SVGHouse } from '../../../svg/house.svg';

// import { ReactComponent as SVGArrow } from '../../../svg/arrow.svg';

// import TextareaAutosize from 'react-textarea-autosize';

import Start from './slideTypes/start';
import Summary from './slideTypes/summary';
import Comments from './slideTypes/comments';
import Question from './slideTypes/question';


const Slide = (props) => {

    //console.log('slide updating');

    const goToNextSection = () => {
        if (window.fullpage_api) {
            window.fullpage_api.moveSectionDown();
        }
    }

    const goToPreviousSection = () => {
        if (window.fullpage_api) {
            window.fullpage_api.moveSectionUp();
        }
    }

    if (!props.slide) {
        return null;
    }

    let classes = classNames(
        'section',
        'section-' + props.index,
        props.slide.type
    );

    if (props.slide.type === 'start') {
        return (
            <Start
                slide={props.slide}
                section={props.section}
                classes={classes}
                goToNextSection={() => goToNextSection()}
                goToPreviousSection={() => goToPreviousSection()}
                sectionNavigationInfo={props.sectionNavigationInfo}
            />
        );
    }

    if (props.slide.type === 'summary') {
        return (
            <Summary
                slide={props.slide}
                section={props.section}
                classes={classes}
                goToNextSection={() => goToNextSection()}
                goToPreviousSection={() => goToPreviousSection()}
            />
        );
    }

    if (props.slide.type === 'comments') {
        return (
            <Comments
                slide={props.slide}
                section={props.section}
                classes={classes}
                goToNextSection={() => goToNextSection()}
                goToPreviousSection={() => goToPreviousSection()}
                answerQuestion={(answer) => props.answerQuestion(answer)}
                sectionNavigationInfo={props.sectionNavigationInfo}
            />
        );
    }

    return (
        <Question
            index={props.index}
            slide={props.slide}
            section={props.section}
            classes={classes}
            goToNextSection={() => goToNextSection()}
            goToPreviousSection={() => goToPreviousSection()}
            answerQuestion={(answer) => props.answerQuestion(answer)}
        />
    );
}

Slide.propTypes = {
    slide: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    answerQuestion: PropTypes.func.isRequired
    // slides: PropTypes.array.isRequired,
    // answers: PropTypes.array.isRequired
};

export default Slide;
