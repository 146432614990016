import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import Section from './section';

class Survey extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.props.bodyClass('survey');
    }

    componentDidMount() {
        //console.log(this.props);
    }

    checkIfSurveyIsInProgress(slideIndex, section) {
        // console.log(slideIndex);
        // console.log(section);

        if (section.slug === this.props.sections[0].slug && slideIndex === 0) {
            return null;
        }
        console.log(this.props.sections[0]);

        if (!this.props.sections[0].questions[1].score) {
            console.log('redirecting');

            this.props.history.push({
                pathname: '/',
                search: this.props.location.search
            });
        } else {
            console.log('not redirecting');
        }
    }

    shouldComponentUpdate(props, state) {
        if (props.redirect === this.props.redirect) {
            return true;
        }

        if (props.redirect) {
            console.log('redirecting ' + props.redirect);

            if (props.redirect.path) {
                props.history.push({
                    pathname: props.redirect.path,
                    search: props.location.search
                });
            }

            setTimeout(() => {
                if (window.fullpage_api) {
                    window.fullpage_api.moveTo(props.redirect.slide);
                }
            }, 500);

            return false;
        }

        return true;
    }

    render() {
        let redirectPath = '/survey/' + this.props.sections[0].slug;

        console.log(this.props.redirect);

        return (
            <React.Fragment>
                <Route exact path="/survey" render={() => <Redirect to={redirectPath} />} />
                {this.props.sections.map(section => (
                    <Route
                        key={section.slug}
                        path={['/survey/' + section.slug]}
                        render={() => (
                            <Section
                                section={section}
                                sections={this.props.sections}
                                setProgress={progress => this.props.setProgress(progress)}
                                answerQuestion={answer => this.props.answerQuestion(answer)}
                                loading={loading => this.props.loading(loading)}
                                setCurrentColor={color => this.props.setCurrentColor(color)}
                                checkIfSurveyIsInProgress={(slideIndex, section) =>
                                    this.checkIfSurveyIsInProgress(slideIndex, section)
                                }
                                unitData={this.props.unitData}
                            />
                        )}
                    />
                ))}
            </React.Fragment>
        );
    }
}

Survey.propTypes = {
    sections: PropTypes.array.isRequired
};

export default Survey;
