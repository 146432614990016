import React, { Component } from 'react';
import classNames from 'classnames';
import Helpers from '../../helpers';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';

import { ReactComponent as SVGLogo } from '../../svg/logo.svg';

import { Table } from 'semantic-ui-react';


class PDF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sectionScores: [],
            ip: '',
            gotIPdata: false
        };
        this.props.bodyClass('pdf');
        this.getIP();
        console.log(this.props.answers);
        console.log(navigator);
    }

    componentDidMount() {
        this.props.loading(false);

        this.setState({
            ...this.state,
            sectionScores: Helpers.calculateScores(this.props.answers)
        }, () => {
            console.log(this.state.sectionScores);
            console.log(this.props.data.fullpage_slides);

        });
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    spiltQuestions(questions, firstHalf) {

        let halfWayThough = Math.floor(questions.length / 2)

        if (firstHalf) {
            return questions.slice(0, halfWayThough);
        } else {
            return questions.slice(halfWayThough, questions.length);
        }
    }

    generatePDF() {

        let htmlpages = document.getElementsByClassName('page');

        var doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4'
        });

        var pages = Array.prototype.slice.call(htmlpages);

        var promises = pages.map(function (page) {
            return new Promise(function (resolve) {
                html2canvas(page).then(canvas => {
                    resolve(canvas);
                });
            });
        });

        Promise.all(promises).then(function (results) {
            results.forEach(function (canvas, index) {
                if (index !== 0) {
                    doc.addPage('a4', 'l');
                }
                console.log('An image was processed');

                var imgData = canvas.toDataURL('image/png');
                doc.addImage(imgData, 'PNG', 0, 0, 297, 210, '', 'FAST');

            });
            doc.save('demo.pdf');
            console.log('Document served!');
        });

    }

    inRange(x, min, max) {
        return ((x - min) * (x - max) <= 0);
    }

    calculatePosition(score) {

        let green = '#53BE4D';
        let yellow = '#F5C301';
        let red = '#C31A48';

        if (this.inRange(score, 1, 2.999)) {
            let procent = (((score - 1) / 3) * 100) / 2;
            return {
                className: 'marker red',
                style: {
                    left: procent + '%',
                    background: red
                }
            }
        } else if (this.inRange(score, 3, 3.999)) {
            let procent = (((score - 2) / 3) * 100);
            return {
                className: 'marker yellow',
                style: {
                    left: procent + '%',
                    background: yellow
                }
            }
        } else {
            let procent = (((score - 2) / 3) * 100);
            return {
                className: 'marker green',
                style: {
                    left: procent + '%',
                    background: green
                }
            }
        }
    }

    //https://api.ipify.org/

    encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    getIP() {
        axios.get('https://api.ipify.org')
            .then(res => {
                const ip = res.data;
                console.log(ip);

                this.setState({
                    ...this.state,
                    gotIPdata: true,
                    ip: ip
                });
            });
    }

    handleSubmit() {

        if (!this.props.answers) {
            return;
        }

        let requestData = this.encode({
            "form-name": "contact",
            "ip": this.state.ip,
            "anwsers-data": JSON.stringify(this.props.answers),
            "browser": navigator.userAgent,
            "languages": navigator.languages.toString(),
            "ga_id": this.getCookie('_ga')
        });

        axios({
            url: '/',
            method: 'post',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: requestData
        }).then(response => {
            console.log('Data sent!');
            console.log(response);
        }).catch(error => {
            console.log(error);
        });

    };

    handleChange(e) {
        console.log(e.target.name);

        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="pages">
                    <button onClick={() => this.generatePDF()}>test</button>
                    {this.state.sectionScores.map((score) =>
                        <section className={score.slug} key={score.slug}>
                            <div className={classNames('page first', 'color-' + score.questions[0].color)}>
                                <header>

                                    <div className="ui container fluid">
                                        <div className="ui grid">
                                            <div className="eleven wide column title">
                                                <h1>{score.questions[0].title}</h1>
                                            </div>
                                            <div className="three wide column middle aligned text">
                                                <p>
                                                    Gennemsnitlige svar:
                                            </p>
                                            </div>
                                            <div className="two wide column middle aligned center aligned number">
                                                <span>
                                                    {score.average}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <section>
                                    <div className="ui container fluid score">
                                        <div className="ui grid">
                                            <div className="sixteen wide column">
                                                <div className="marker-wrapper">
                                                    <div {...this.calculatePosition(score.average)}>
                                                        {score.average}
                                                        <div className="arrow-down"></div>
                                                    </div>
                                                    <div className="number number-1">
                                                        1
                                                </div>
                                                    <div className="number number-3">
                                                        3
                                                </div>
                                                    <div className="number number-4">
                                                        4
                                                </div>
                                                    <div className="number number-5">
                                                        5
                                                </div>
                                                </div>

                                                <div className="ui grid three column">
                                                    <div className="column ">
                                                        <div className="range bad">
                                                        </div>
                                                    </div>
                                                    <div className="column ">
                                                        <div className="range okay">
                                                        </div>
                                                    </div>
                                                    <div className="column ">
                                                        <div className="range great">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ui container fluid summary">
                                        <div className="ui grid">
                                            <div className="eight wide column">
                                                <Table basic='very'>
                                                    <Table.Body>
                                                        {this.spiltQuestions(score.questions, true).map((question) =>
                                                            <Table.Row key={question.id}>
                                                                <Table.Cell textAlign='center' className="number" width={2}>
                                                                    {question.questionIndex - 1}
                                                                </Table.Cell>
                                                                <Table.Cell className="question" width={12}>
                                                                    {this.props.data.fullpage_slides[question.id].question}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='center' className="answer" width={2}>
                                                                    <span>{question.value}</span>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                            <div className="eight wide column">
                                                <Table basic='very'>
                                                    <Table.Body>
                                                        {this.spiltQuestions(score.questions, false).map((question) =>
                                                            <Table.Row key={question.id}>
                                                                <Table.Cell textAlign='center' className="number" width={2}>
                                                                    {question.questionIndex - 1}
                                                                </Table.Cell>
                                                                <Table.Cell className="question" width={12}>
                                                                    {this.props.data.fullpage_slides[question.id].question}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='center' className="answer" width={2}>
                                                                    <span>{question.value}</span>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="logo">
                                    <SVGLogo />
                                </div>
                            </div>
                            <div className={classNames('page last', 'color-' + score.questions[0].color)}>
                                <header>
                                    <div className="ui container fluid">
                                        <div className="ui grid">
                                            <div className="eleven wide column title">
                                                <h1>{score.questions[0].title}</h1>
                                            </div>
                                            <div className="three wide column middle aligned text">
                                                <p>
                                                    Gennemsnitlige svar:
                                            </p>
                                            </div>
                                            <div className="two wide column middle aligned center aligned number">
                                                <span>
                                                    {score.average}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <section>
                                    <div className="ui container fluid summary">
                                        <div className="ui grid">
                                            <div className="sixteen wide column comments">
                                                <h3>Tilføjet kommentarer:</h3>
                                                {score.comments.map((comment) =>

                                                    <div className="comment boxed" key={comment.id}>
                                                        <h3>
                                                            {comment.title}
                                                        </h3>
                                                        {comment.value}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="logo">
                                    <SVGLogo />
                                </div>
                            </div>

                        </section>

                    )}
                    <footer></footer>
                </div>

            </React.Fragment>
        )
    }
}

export default PDF;