import React, { Component } from 'react';

class Page404 extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        return (
            <div><h1>404</h1></div>
        );
    }
}

export default Page404;
