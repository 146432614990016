import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Dropdown } from 'semantic-ui-react';

import { ReactComponent as SVGSmallArrow } from '../../svg/small_arrow.svg';
import { ReactComponent as SVGArrow } from '../../svg/arrow.svg';
import { ReactComponent as SVGDots } from '../../svg/dots2.svg';
//import { ReactComponent as SVGArrow } from '../../svg/arrow.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Questions extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            industries: this.props.surveyData.client_questions.industries.value,
            client_type: this.props.surveyData.client_questions.client_type.value,
            company_size: this.props.surveyData.client_questions.company_size.value
        }

        this.test = false;

    }

    shouldComponentUpdate(props, state) {

        if (props.redirect === this.props.redirect) {
            return true;
        }

        if (props.redirect) {
            console.log('redirecting ' + props.redirect);

            if (props.redirect.path) {
                props.history.push({
                    pathname: props.redirect.path,
                    search: props.location.search
                });
            }

            setTimeout(() => {
                if (window.fullpage_api) {
                    window.fullpage_api.moveTo(props.redirect.slide);
                }
            }, 500);

            return false;
        }

        return true;
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.loading(false);
        }, 1000);
        this.props.bodyClass('questions');
    }

    componentWillMount() {
        this.props.loading(true);
    }

    sendAnswers(name, value) {
        this.props.sendAnswers({
            name: name,
            value: value
        });
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value }, () => {
            this.sendAnswers(name, value);
        });
    }

    handleButtonChange(name, value) {
        this.setState({ [name]: value }, () => {
            this.sendAnswers(name, value);
        });
    }

    render() {
        //console.log(this.props);

        let linkClasses = classNames({
            'active': (this.state.client_type && this.state.industries && this.state.company_size)
        }, 'link-wrapper');

        let path = {
            pathname: '/survey/' + this.props.surveyData.sections[0].slug,
            search: window.location.search
        };

        return (

            <div className="ui container questions">
                <div className="dots">
                    <SVGDots />
                </div>
                <div className="ui middle aligned centered aligned one column grid">
                    <div className="row">
                        <div className="fourteen wide column questions-wrapper">
                            <div className="content">
                                <h1>Vi har brug for nogle oplysninger</h1>

                                <div className="company-size group">
                                    <h4>Virksomhedsstørrelse</h4>

                                    {this.props.surveyData.client_questions.company_size.values.map((company_size) =>
                                        <Button
                                            basic
                                            content={company_size.text}
                                            onClick={() => this.handleButtonChange('company_size', company_size.id)}
                                            className={classNames({ 'active': (this.state.company_size === company_size.id) })}
                                            key={company_size.id}
                                        />
                                    )}
                                </div>

                                <div className="type group">
                                    <h4>Offentlig/privat</h4>

                                    {this.props.surveyData.client_questions.client_type.values.map((client_type) =>
                                        <Button
                                            basic
                                            content={client_type.text}
                                            onClick={() => this.handleButtonChange('client_type', client_type.id)}
                                            className={classNames({ 'active': (this.state.client_type === client_type.id) })}
                                            key={client_type.id}
                                        />
                                    )}

                                </div>

                                <div className="branche group">
                                    <h4>Branche</h4>
                                    <div className="dropdown-wrapper">
                                        <Dropdown
                                            placeholder='Vælg en branche'
                                            fluid
                                            basic
                                            selection
                                            name="industries"
                                            defaultValue={this.state.industries}
                                            // upward={true}
                                            defaultOpen={false}
                                            options={this.props.surveyData.client_questions.industries.values}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <SVGSmallArrow />
                                    </div>
                                </div>


                                <div className={linkClasses}>
                                    <Link to={path}>
                                        <div className="action ui grid">
                                            <div className="ui eight wide column right aligned middle aligned">
                                                <h3>Næste</h3>
                                            </div>
                                            <div className="ui eight wide column">
                                                <SVGArrow />
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

Questions.propTypes = {
    bodyClass: PropTypes.func.isRequired,
    surveyData: PropTypes.object.isRequired,
    sendAnswers: PropTypes.func.isRequired
};

export default Questions