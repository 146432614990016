import React from 'react';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import { Button, Table } from 'semantic-ui-react';

// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import axios from 'axios';

import Helpers from '../../helpers';

import { ReactComponent as SVGArrow } from '../../svg/arrow.svg';
import { ReactComponent as SVGDownload } from '../../svg/download.svg';
import mountainDone from '../../svg/complete.png';
//import { ReactComponent as SVGMountain } from '../../svg/mountainclimb.svg';
import { ReactComponent as SVGDots4 } from '../../svg/dots4.svg';
import { ReactComponent as SVGDots5 } from '../../svg/dots5.svg';

import PropTypes from 'prop-types';

class Complete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            downloadUrl: false,
            done: false,
            sectionScores: [],
            surveyData: null
        };
        this.generatePDF = this.generatePDF.bind(this);
        this.getIP();
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.loading(false);
        }, 1000);
        this.props.bodyClass('complete');
        this.saveDataToSessionStorage();
    }

    componentWillMount() {
        this.props.loading(true);
    }

    shouldComponentUpdate(props, state) {
        if (props.redirect === this.props.redirect) {
            return true;
        }

        if (props.redirect) {
            console.log('redirecting ' + props.redirect);

            if (props.redirect.path) {
                props.history.push({
                    pathname: props.redirect.path,
                    search: props.location.search
                });
            }
            setTimeout(() => {
                if (window.fullpage_api) {
                    window.fullpage_api.moveTo(props.redirect.slide);
                }
            }, 500);
            return false;
        }
        return true;
    }

    saveDataToSessionStorage() {
        if (!sessionStorage.getItem('surveyData')) {
            console.log('no session data found');
            console.log(this.props.surveyData);

            this.setState({
                surveyData: this.props.surveyData
            });
            sessionStorage.setItem('surveyData', JSON.stringify(this.props.surveyData));
        } else {
            console.log(this.props.surveyData);

            if (this.props.surveyData && this.props.surveyData.sections[0].average) {
                console.log('new survey data found');
                this.setState({
                    surveyData: this.props.surveyData
                });
                sessionStorage.setItem('surveyData', JSON.stringify(this.props.surveyData));
            } else {
                console.log('old survey data found');
                this.setState({
                    surveyData: JSON.parse(sessionStorage.getItem('surveyData'))
                });
            }
        }
    }

    getCookie(cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    spiltQuestions(questions, firstHalf) {
        let halfWayThough = Math.floor(questions.length / 2);

        if (firstHalf) {
            return questions.slice(0, halfWayThough);
        } else {
            return questions.slice(halfWayThough, questions.length);
        }
    }

    generatePDF() {
        this.setState({
            loading: true
        });
        //let completeThis = this;
        axios({
            url: Helpers.isDev()
                ? 'https://cabiadmin.app/api/result/pdf'
                : 'https://admin.cabisurvey.umano.dev/api/result/pdf',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            data: {
                data: JSON.stringify(this.state.surveyData)
            }
        })
            .then(response => {
                sessionStorage.setItem('surveyDataSent', true);
                this.setState({
                    loading: false,
                    downloadUrl: 'https://umano-cabi.s3.eu-west-2.amazonaws.com' + response.data
                });
                console.log('downloaded!');
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    inRange(x, min, max) {
        return (x - min) * (x - max) <= 0;
    }

    calculatePosition(score) {
        let green = '#53BE4D';
        let yellow = '#F5C301';
        let red = '#C31A48';

        if (this.inRange(score, 1, 2.999)) {
            let procent = (((score - 1) / 3) * 100) / 2;
            return {
                className: 'marker red',
                style: {
                    left: procent + '%',
                    background: red
                }
            };
        } else if (this.inRange(score, 3, 3.999)) {
            let procent = ((score - 2) / 3) * 100;
            return {
                className: 'marker yellow',
                style: {
                    left: procent + '%',
                    background: yellow
                }
            };
        } else {
            let procent = ((score - 2) / 3) * 100;
            return {
                className: 'marker green',
                style: {
                    left: procent + '%',
                    background: green
                }
            };
        }
    }

    encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    }

    getIP() {
        axios.get('https://api.ipify.org').then(res => {
            const ip = res.data;
            console.log(ip);
            console.log(this.props);

            this.sendData(ip);
        });
    }

    sendData(ip) {
        console.log('starting sending');

        console.log(this.props.redirect);

        if (this.props.unitData && this.props.unitData.progress !== 100) {
            console.log('Survey progress is not 100%. Sending data stopped');
            return;
        }

        if (sessionStorage.getItem('surveyDataSent')) {
            console.log('Data already sent');
            return;
        }

        if (this.state.surveyData.sections.length === 0) {
            console.log('No answers data found. Sending of data stopped.');
            return;
        }

        let uuid = null;
        let survey_type = 'default';

        if (this.props.unitData) {
            uuid = this.props.unitData.survey_id;
            survey_type = this.props.unitData.screening.type;
        }

        let requestData = {
            company_size: this.state.surveyData.client_questions.company_size.value,
            client_type: this.state.surveyData.client_questions.client_type.value,
            industry: this.state.surveyData.client_questions.industries.value,
            ip: ip,
            languages: navigator.languages.toString(),
            browser: navigator.userAgent,
            ga_id: this.getCookie('_ga'),
            survey_data: JSON.stringify(this.state.surveyData),
            survey_type: survey_type,
            unit_id: uuid
        };

        axios({
            url: Helpers.isDev()
                ? 'https://cabiadmin.app/api/result/'
                : 'https://admin.cabisurvey.umano.dev/api/result/',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            data: requestData
        })
            .then(response => {
                sessionStorage.setItem('surveyDataSent', true);
                this.saveProgress();
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    saveProgress() {
        if (this.props.unitData) {
            console.log('saving data');

            let requestData = {
                survey_data: JSON.stringify(this.state.surveyData)
            };

            axios({
                url: Helpers.isDev()
                    ? 'https://cabiadmin.app/api/unit/' + this.props.unitData.survey_id
                    : 'https://admin.cabisurvey.umano.dev/api/unit/' + this.state.unitData.survey_id,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                data: requestData
            })
                .then(response => {
                    const unitData = response.data;
                    this.setState({
                        unitData: unitData
                    });
                    console.log(unitData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    checkIfCommentIsFilled(comment) {
        return comment.value ? comment.value : 'Ingen kommentar udfyldt';
    }

    renderPDF() {
        if (!this.state.surveyData) {
            return null;
        }

        return (
            <div className="pages">
                {this.state.surveyData.sections.map(section => (
                    <section className={section.slug} key={section.slug}>
                        <div className={classNames('page first', 'color-' + section.color)}>
                            <header>
                                <div className="ui container fluid">
                                    <div className="ui grid">
                                        <div className="eleven wide column title">
                                            <h1>{section.title}</h1>
                                        </div>
                                        <div className="three wide column middle aligned text">
                                            <p>Gennemsnitlige svar:</p>
                                        </div>
                                        <div className="two wide column middle aligned center aligned number">
                                            <span>{section.average}</span>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <section>
                                <div className="ui container fluid score">
                                    <div className="ui grid">
                                        <div className="sixteen wide column">
                                            <div className="marker-wrapper">
                                                <div {...this.calculatePosition(section.average)}>
                                                    {section.average}
                                                    <div className="arrow-down"></div>
                                                </div>
                                                <div className="number number-1">1</div>
                                                <div className="number number-3">3</div>
                                                <div className="number number-4">4</div>
                                                <div className="number number-5">5</div>
                                            </div>

                                            <div className="ui grid three column">
                                                <div className="column ">
                                                    <div className="range bad"></div>
                                                </div>
                                                <div className="column ">
                                                    <div className="range okay"></div>
                                                </div>
                                                <div className="column ">
                                                    <div className="range great"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ui container fluid summary">
                                    <div className="ui grid">
                                        <div className="eight wide column">
                                            <Table basic="very">
                                                <Table.Body>
                                                    {this.spiltQuestions(section.questions, true).map(question => (
                                                        <Table.Row key={question.id}>
                                                            <Table.Cell textAlign="center" className="number" width={2}>
                                                                {question.id}
                                                            </Table.Cell>
                                                            <Table.Cell className="question" width={12}>
                                                                {question.question}
                                                            </Table.Cell>
                                                            <Table.Cell textAlign="center" className="answer" width={2}>
                                                                <span>{question.score}</span>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                        <div className="eight wide column">
                                            <Table basic="very">
                                                <Table.Body>
                                                    {this.spiltQuestions(section.questions, false).map(question => (
                                                        <Table.Row key={question.id}>
                                                            <Table.Cell textAlign="center" className="number" width={2}>
                                                                {question.id}
                                                            </Table.Cell>
                                                            <Table.Cell className="question" width={12}>
                                                                {question.question}
                                                            </Table.Cell>
                                                            <Table.Cell textAlign="center" className="answer" width={2}>
                                                                <span>{question.score}</span>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className={classNames('page last', 'color-' + section.color)}>
                            <header>
                                <div className="ui container fluid">
                                    <div className="ui grid">
                                        <div className="eleven wide column title">
                                            <h1>{section.title}</h1>
                                        </div>
                                        <div className="three wide column middle aligned text">
                                            <p>Gennemsnitlige svar:</p>
                                        </div>
                                        <div className="two wide column middle aligned center aligned number">
                                            <span>{section.average}</span>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <section>
                                <div className="ui container fluid summary">
                                    <div className="ui grid">
                                        <div className="sixteen wide column comments">
                                            <h3>Tilføjet kommentarer:</h3>
                                            {section.comments.map(comment => (
                                                <div className="comment boxed" key={comment.id}>
                                                    <h3>{comment.title}</h3>
                                                    {this.checkIfCommentIsFilled(comment)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                ))}
            </div>
        );
    }

    getPreviousSection() {
        if (!this.state.surveyData) {
            return null;
        }
        let sectionsLength = this.state.surveyData.sections.length;
        return {
            slug: this.state.surveyData.sections[sectionsLength - 1].slug,
            title: this.state.surveyData.sections[sectionsLength - 1].title
        };
    }

    backButton() {
        if (!this.state.surveyData.isV2) {
            let path = {
                pathname: '/survey/' + this.getPreviousSection().slug,
                search: window.location.search
            };
            return (
                <div className="left floated column navigation-box box-left">
                    <div className="wrapper">
                        <h3>Tilbage</h3>
                        <span>{this.getPreviousSection().title}</span>
                        <SVGArrow />
                    </div>
                    <Link to={path}></Link>
                </div>
            );
        }
    }

    downloadPDF() {
        // Disabled for now
        //if (!this.state.surveyData.isV2) {
        if (this.state.downloadUrl) {
            return (
                <React.Fragment>
                    <SVGDownload />
                    <a
                        href={this.state.downloadUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ui basic button default"
                    >
                        Download PDF
                    </a>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <SVGDownload />
                    <Button
                        basic
                        content="Generere PDF"
                        className="default"
                        onClick={() => this.generatePDF()}
                        loading={this.state.loading}
                    />
                </React.Fragment>
            );
        }

        //}
    }

    downloadPDFWithText() {
        // Disabled for now
        //if (!this.state.surveyData.isV2) {
        return (
            <React.Fragment>
                <div className="twelve wide column content">
                    <h2>Download din pdf nu:</h2>
                    <p>
                        Ville du gemme dine resultater kan du downloade data her.
                        <br />
                        Pdf’en viser en uddybende version af dine resultater som giver et godt overblik at arbejde
                        videre med omkring jeres sygefraværsindsats.
                    </p>
                </div>
                <div className="four wide column center aligned download-bottom">{this.downloadPDF()}</div>
                <div className="dots">
                    <SVGDots4 />
                </div>
            </React.Fragment>
        );
        //}
    }

    render() {
        if (!this.state.surveyData) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderPDF()}
                <div className="ui container">
                    <div className="ui grid">{this.backButton()}</div>
                    <div className="ui grid centered top-section">
                        <div className="twelve wide column ">
                            <div className="content">
                                <h1>Resultat</h1>
                                <h3>Oversigt over svar</h3>
                            </div>
                            <div className="dots">
                                <SVGDots5 />
                            </div>
                        </div>
                        <div className="four wide column center aligned download-top">{this.downloadPDF()}</div>
                    </div>

                    <div className="ui grid results centered">
                        <div className="sixteen wide column">
                            {this.state.surveyData.sections.map(section => (
                                <div
                                    className={classNames('ui grid result', 'color-' + section.color)}
                                    key={section.slug}
                                >
                                    <div className="nine wide column title">
                                        <h2>{section.title}</h2>
                                    </div>
                                    <div className="four wide column right aligned text">Gennemsnitlige svar:</div>
                                    <div className="three wide column score">
                                        <span>{section.average}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="ui grid final-notes centered">{this.downloadPDFWithText()}</div>

                    <div className="ui grid thank-you centered">
                        <div className="sixteen wide column content center aligned">
                            <h2>{this.state.surveyData.outtro.title}</h2>
                        </div>
                    </div>
                    <div className="ui grid mountain-complete centered">
                        <div className="sixteen wide column content center aligned">
                            <img src={mountainDone} alt="" />
                        </div>
                    </div>
                </div>
                <div className="ui container final-links">
                    <div className="ui three column grid centered">
                        <div className="row">
                            {/* <div className="column">
                                <div className="inner">
                                    <p>Du er nu på Version 1, og dine resultater vil blive nulstillet efter du har forladt siden.</p>
                                    <div className="arrow">
                                        <h3>Gå til</h3>
                                        <p>Version 2</p>
                                        <SVGArrow />
                                    </div>
                                </div>
                            </div> */}
                            <div className="column">
                                <div className="inner">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.surveyData.outtro.text
                                        }}
                                    ></span>
                                    {/* <div className="arrow">
                                        <h3>Gå til</h3>
                                        <p>Version 2</p>
                                        <SVGArrow />
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="column">
                                <div className="inner">
                                    <p>Du er nu på Version 1, og dine resultater vil blive nulstillet efter du har forladt siden.</p>
                                    <div className="arrow">
                                        <h3>Gå til</h3>
                                        <p>Version 2</p>
                                        <SVGArrow />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Complete.propTypes = {
    bodyClass: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
    surveyData: PropTypes.object.isRequired
};

export default Complete;
