const Helpers = {

    sendGAEvent(event, category, action, label, value) {
        if (window.dataLayer) {
            window.dataLayer.push({
                'event': event,
                'eventCategory': category,
                'eventAction': action,
                'eventLabel': label,
                'eventValue': value
            });
        }
    },

    getUrlParameter(name) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },


    isDev() {
        return (window.location.host === 'localhost:3000');
    }

}

export default Helpers;