import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SVGArrow } from '../../../../svg/arrow.svg';
import { Link } from 'react-router-dom';

class Start extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

        };
        //console.log(this.props.sectionNavigationInfo);

    }

    backButtonOnFirstSlide() {
        if (!this.props.sectionNavigationInfo.previousSection) {
            let path = {
                pathname: '/questions',
                search: window.location.search
            };
            return (
                <div className="right floated column navigation-box box-left">
                    <div className="wrapper">
                        <h3>Tilbage</h3>
                        <span>Startspørgsmål</span>
                        <SVGArrow />
                    </div>
                    <Link to={path}></Link>
                </div>
            )
        } else {
            let path = {
                pathname: '/survey/' + this.props.sectionNavigationInfo.previousSection.slug,
                search: window.location.search
            };
            return (
                <div className="right floated column navigation-box box-left">
                    <div className="wrapper">
                        <h3>Tilbage</h3>
                        <span>{this.props.sectionNavigationInfo.previousSection.title}</span>
                        <SVGArrow />
                    </div>
                    <Link to={path}></Link>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={this.props.classes} data-color={this.props.slide.color}>
                <div className="ui container">
                    <div className="ui grid">
                        <div className="seven wide column huset">
                            <img src={window.location.protocol + '//' + window.location.host + '/svg/huset/' + this.props.section.slug + '.svg'} alt="huset" />
                        </div>
                        <div className="nine wide column">
                            <h1>
                                {this.props.slide.title}
                            </h1>
                            <span dangerouslySetInnerHTML={{ __html: this.props.slide.description }}>

                            </span>
                            <section className="bottom-navigation">
                                <div className="ui container">
                                    <div className="ui grid">
                                        <div className="two column row">
                                            {this.backButtonOnFirstSlide()}
                                            <div className="left floated column navigation-box filled" onClick={() => this.props.goToNextSection()}>
                                                <div className="wrapper">
                                                    <h3>Næste</h3>
                                                    <span>Spørgsmål</span>
                                                    <SVGArrow />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Start.propTypes = {
    slide: PropTypes.object.isRequired
};

export default Start;
