import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Slide extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {
        let section_title = this.props.section.slug;
        return (
            <React.Fragment>
                <div className={this.props.className}>
                    <div className="navigation" id="menu">
                        <ul key={section_title}>
                            {this.props.section.slides.map((item, index) => (
                                <li data-menuanchor={section_title + '_' + index} key={index}>
                                    <a href={'#' + section_title + '_' + index}>#</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Slide.propTypes = {
    section: PropTypes.object.isRequired
};

export default Slide;
