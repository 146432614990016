import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { HotKeys } from "react-hotkeys";
// import slug from 'slug';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as SVGDots } from '../../svg/dots.svg';

import Slide from './slide/slide.js';
import Navigation from '../navigation';


class Section extends Component {


    constructor(props) {
        super(props);

        this.state = {
            slideType: '',
            currentIndex: 0,
            anchors: null,
            sectionNavigationInfo: {},
            keyJustPressed: false
        };
        //console.log('section');

        //this.NavigationElement = React.createRef();
        //this.props.bodyClass('survey');
    }

    componentDidMount() {
        //console.log(this.props.surveyData);

        this.prepareSlidesAndCreateAnchors();
        this.props.setCurrentColor(this.props.section.color);
    }

    componentWillMount() {
        this.getSectionInfo();

    }

    componentDidUpdate() {
        //console.log('[section] updating');
    }

    getSectionInfo() {

        const index = this.props.sections.findIndex(section => section.slug === this.props.section.slug);

        let sectionNavigationInfo = {
            previousSection: this.props.sections[index - 1],
            nextSection: this.props.sections[index + 1]
        }

        this.setState({
            sectionNavigationInfo: sectionNavigationInfo
        }, () => {
            //console.log(this.state);
        });
    }

    /* 
       Check what current slide is 
   */
    isWhatSlide(currentIndex) {

        let currentSlideType = this.props.section.slides[currentIndex].type;

        // Save it to state
        this.setState({
            currentIndex: currentIndex,
            slideType: currentSlideType
        });
    }

    // /* 
    //     Check if questions has been answered
    // */
    checkIfQuestionHasBeenAnswered() {

        // Check if slide is a question
        if ((this.state.slideType !== 'question')) {
            return true;
        }

        if (this.props.section.questions[this.state.currentIndex - 1].score) {
            return true;
        }
        return false;
    }

    /* 
        When you leave a slide
    */
    onLeave(origin, destination, direction) {

        // // Check is question is missing a answer before going down
        if (direction === 'down' && !this.checkIfQuestionHasBeenAnswered()) {
            console.log('not allowed');
            return false;
        }

        // Send progress info up to the parent component
        this.props.setProgress({
            section: this.props.section,
            index: destination.index
        });

        // // Find out what kind of slide the new one is
        this.isWhatSlide(destination.index);
    }

    /* 
        When all slides are loaded
    */
    afterLoad(origin, destination, direction) {


    }

    /* 
        When Fullpage is done rendering everything
    */
    afterRender(slide) {



        // // Find out what kind of slide the current one is
        this.isWhatSlide(slide.index);

        this.props.setProgress({
            section: this.props.section,
            index: slide.index
        });

        //console.timeEnd('Loading survey');
        setTimeout(() => {
            // Finish loading
            this.props.loading(false);
            this.props.checkIfSurveyIsInProgress(this.state.currentIndex, this.props.section);
        }, 1000);
    }

    /* 
        After resize show loading overlay and rebuild the fullpage
    */
    afterResize() {
        if (window.fullpage_api) {

            console.log('rebuilding');
            this.props.loading(true);

            // This is fucked and i have no idea why it is working like that
            window.fullpage_api.reBuild();

            setTimeout(() => {
                window.fullpage_api.reBuild();
                setTimeout(() => {
                    window.fullpage_api.reBuild();
                    setTimeout(() => {
                        window.fullpage_api.reBuild();
                        this.props.loading(false);
                    }, 1000);
                }, 1000);
            }, 1000);

        }
    }

    goToPreviousSection() {
        if (window.fullpage_api) {
            window.fullpage_api.moveSectionUp();
        }
    }

    goToNextSection() {
        if (window.fullpage_api) {
            window.fullpage_api.moveSectionDown();
        }
    }

    checkHotKeyBeforeAnswering(value) {

        if ((this.state.slideType === 'question') && !this.state.keyJustPressed) {
            this.props.answerQuestion({
                type: 'question',
                value: value,
                section: this.props.section,
                slide: this.props.section.questions[this.state.currentIndex - 1]
            });
            setTimeout(() => {
                this.goToNextSection();
            }, 700);
            this.setState({
                keyJustPressed: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        keyJustPressed: false
                    });
                }, 500);
            });
        }
    }


    prepareSlidesAndCreateAnchors() {

        let section = this.props.section;

        if (section.slides.findIndex(slide => slide.type === "questions") !== -1) {
            const index = section.slides.findIndex(slide => slide.type === "start");
            section.slides.splice(index + 1, 1, ...this.props.section.questions);
        }

        this.setState({
            section: section
        }, () => {
            let anchors = this.props.section.slides.map((slide, index) => {
                return this.props.section.slug + '_' + index;
            });
            this.setState({
                anchors: anchors
            }, () => {
                //console.log(this.state);

            });
        });

    }

    /* 
        Save data and execute functions when you leave a slide
    */
    renderSurvey() {
        //console.log(this.props.sections);

        const fullpageProps = {
            debug: false,
            menu: '#menu',
            afterResize: this.afterResize.bind(this),
            onLeave: this.onLeave.bind(this),
            afterLoad: this.afterLoad.bind(this),
            afterRender: this.afterRender.bind(this),
            setAllowScrolling: ((this.state.slideType === 'comments') || (this.state.slideType === 'summary')) ? 'true' : 'false',

            normalScrollElements: '.scroll-wrapper',
            animateAnchor: false,
            //recordHistory: false,

            anchors: this.state.anchors,
            licenseKey: "1FF6A243-DDFB4C0D-8F0B5CE4-2F84BCF5",
            offsetSections: false,
            offsetSectionsKey: "dW1hbm8uZGV2X3RRYmIyWm1jMlYwVTJWamRHbHZibk09aHRR"
        };

        return (
            <ReactFullpage {...fullpageProps}
                render={({ state, fullpageApi }) => {

                    // Is this an End slide? Otherwise continue
                    if (fullpageApi) {
                        ((this.state.slideType === 'comments') || (this.state.slideType === 'summary')) ? fullpageApi.setAllowScrolling(false) : fullpageApi.setAllowScrolling(true);
                    }

                    return (
                        <ReactFullpage.Wrapper>
                            {this.props.section.slides.map((slide, index) =>
                                <Slide
                                    slide={slide}
                                    index={index}
                                    key={index}
                                    section={this.props.section}
                                    sectionNavigationInfo={this.state.sectionNavigationInfo}
                                    answerQuestion={(answer) => this.props.answerQuestion(answer)}
                                />
                            )}
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        );
    }

    render() {

        //console.log('rendering');


        if (!this.props.section.slides) {
            return null;
        }

        if (!this.state.anchors) {
            return null;
        }

        const keyMap = {
            one: '1',
            two: '2',
            three: '3',
            four: '4',
            five: '5'
        };

        const handlers = {
            one: () => this.checkHotKeyBeforeAnswering(1),
            two: () => this.checkHotKeyBeforeAnswering(2),
            three: () => this.checkHotKeyBeforeAnswering(3),
            four: () => this.checkHotKeyBeforeAnswering(4),
            five: () => this.checkHotKeyBeforeAnswering(5)
        };

        let overlays = classNames({
            'start-overlay': (this.state.slideType === 'start') ? true : false,
            'end-overlay': (this.state.slideType === 'comments') ? true : false
        });

        let colorTheme = classNames(
            'color-' + this.props.section.color,
            'color-wrapper'
        );

        let sidebar = classNames({
            'hide': (this.state.slideType === 'start') ? true : false
        }, 'sidebar');

        let sidebarBack = classNames({
            'hide': (this.state.slideType === 'start') ? true : false
        }, 'sidebar-back');

        let navigationProps = {
            section: this.props.section,
            className: sidebar
        }


        return (
            <React.Fragment>
                <HotKeys keyMap={keyMap}>
                    <HotKeys handlers={handlers}>
                        <div className={classNames(overlays, colorTheme, 'background-drop')}>
                            <div className="ui container">
                                <div className="dots">
                                    <SVGDots />
                                </div>
                                <div className="square">
                                </div>
                                <div className={sidebarBack}>
                                </div>
                            </div>
                        </div>
                        <div className={classNames(overlays, colorTheme)}>
                            <Navigation {...navigationProps} />
                            {this.renderSurvey()}
                        </div>
                    </HotKeys>
                </HotKeys>
            </React.Fragment>
        )
    }

}

Section.propTypes = {
    section: PropTypes.object.isRequired,
    // index: PropTypes.number.isRequired,
    // slides: PropTypes.array.isRequired,
    // answers: PropTypes.array.isRequired
};

export default Section;
