import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { ReactComponent as SVGArrow } from '../../../../svg/arrow.svg';

class Summary extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    checkIfScoreIsSet(score) {
        if (score) {
            return (
                <span>
                    {score}
                </span>
            );
        }
        return 'Not set';
    }


    render() {

        return (
            <div className={this.props.classes} data-color={this.props.slide.color}>
                <div className="scroll-wrapper">
                    <section className="top-navigation">
                        <div className="ui container">
                            <div className="ui grid centered">
                                <div className="navigation-box box-left up" onClick={() => this.props.goToPreviousSection()}>
                                    <div className="wrapper">
                                        <h3>Tilbage</h3>
                                        <span>Spørgsmålene</span>
                                        <SVGArrow />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="ui container summary-list">
                        <div className="ui grid">
                            <div className="sixteen wide column">
                                <Table basic='very'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell textAlign='left'>Oversigt over spørgsmål</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Svar</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.props.section.questions.map((answer) => (
                                            <Table.Row key={answer.id}>
                                                <Table.Cell textAlign='center' className="number" width={2}>
                                                    {answer.id}
                                                </Table.Cell>
                                                <Table.Cell className="question" width={11}>
                                                    {answer.question}
                                                </Table.Cell>
                                                <Table.Cell textAlign='center' className="answer" width={3}>
                                                    {this.checkIfScoreIsSet(answer.score)}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <section className="bottom-navigation">
                        <div className="ui container">
                            <div className="ui grid">
                                <div className="two column row">
                                    <div className="right floated column navigation-box box-left" onClick={() => this.props.goToPreviousSection()}>
                                        <div className="wrapper">
                                            <h3>Forrige</h3>
                                            <span>Spørgsmålene</span>
                                            <SVGArrow />
                                        </div>
                                    </div>
                                    <div className="left floated column navigation-box filled" onClick={() => this.props.goToNextSection()}>
                                        <div className="wrapper">
                                            <h3>Næste</h3>
                                            <span>Kommentar</span>
                                            <SVGArrow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

Summary.propTypes = {
    section: PropTypes.object.isRequired
};

export default Summary;
