import React from "react";
import { Link } from "react-router-dom";

import { Button, Modal, Header, Icon } from "semantic-ui-react";

import { ReactComponent as SVGArrow } from "../../svg/arrow.svg";
// import { ReactComponent as SVGMountain } from '../../svg/mountainclimb.svg';
import { ReactComponent as SVGDots } from "../../svg/dots3.svg";

import PropTypes from "prop-types";

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        };
        if (navigator.userAgent.indexOf("MSIE") >= 0) {
            this.state = {
                modalOpen: true
            };
        }
    }

    handleOpen = () => this.setState({ modalOpen: true });
    handleClose = () => this.setState({ modalOpen: false });

    componentDidMount() {
        setTimeout(() => {
            this.props.loading(false);
        }, 1000);
        this.props.bodyClass("intro");
    }

    shouldComponentUpdate(props, state) {
        if (props.redirect === this.props.redirect) {
            return true;
        }

        if (props.redirect) {
            console.log("redirecting " + props.redirect);

            if (props.redirect.path) {
                props.history.push({
                    pathname: props.redirect.path,
                    search: props.location.search
                });
            }

            setTimeout(() => {
                if (window.fullpage_api) {
                    window.fullpage_api.moveTo(props.redirect.slide);
                }
            }, 500);

            return false;
        }

        return true;
    }

    componentWillMount() {
        this.props.loading(true);
        console.log(this.props);
    }

    render() {
        //console.log(this.props);

        let path = {};

        if (this.props.unitData) {
            path = {
                pathname: "/survey/" + this.props.surveyData.sections[0].slug,
                search: window.location.search
            };
        } else {
            path = {
                pathname: "/questions",
                search: window.location.search
            };
        }

        return (
            <div className="ui container">
                <div className="ui middle aligned one column grid">
                    <div className="row">
                        <div className="nine wide large screen tweleve wide computer fifteen wide tablet column content-wrapper">
                            <div className="content">
                                <h1>{this.props.surveyData.intro.title}</h1>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.surveyData.intro.text
                                    }}
                                ></span>
                                {/* <ul>
                                    <li>
                                        <span>1</span><span className="dash"></span> Ingen grad
                                    </li>
                                    <li>
                                        <span>2</span><span className="dash"></span> Mindre grad
                                    </li>
                                    <li>
                                        <span>3</span><span className="dash"></span> I nogen grad
                                    </li>
                                    <li>
                                        <span>4</span><span className="dash"></span> Høj grad
                                    </li>
                                    <li>
                                        <span>5</span><span className="dash"></span> Meget høj grad
                                    </li>
                                </ul> */}
                            </div>

                            <Link to={path}>
                                <div className="action ui grid">
                                    <div className="ui eight wide column right aligned middle aligned">
                                        <h3>Næste</h3>
                                    </div>
                                    <div className="ui eight wide column">
                                        <SVGArrow />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="background-svg">
                    {/* <SVGMountain /> */}
                    <img src="./images/frontpage.png" alt="frontpage" />
                </div>
                <div className="dots">
                    <SVGDots />
                </div>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    size="small"
                >
                    <Modal.Content>
                        <header>
                            <h2>OBS</h2>
                            <i
                                className="close icon"
                                onClick={this.handleClose}
                            ></i>
                        </header>
                        <section>
                            <h3>
                                Cabi Survey kan ikke afvikles optimalt i denne
                                browser.
                            </h3>
                            <p>
                                Skift til Chrome, Safari, Firefox, Edge samt
                                Internet Explorer 11 eller nyere, så er du
                                sikker på bedre brugeroplevelse - og kan
                                downloade PDF.
                            </p>
                        </section>
                    </Modal.Content>
                </Modal>
                {/* <div className="popup-overlay">
                    <div class="popup">
                        <header>
                            <h2>OBS</h2>
                        </header>
                        <section>
                            <h3>
                                Cabi Survey kan ikke afvikles optimalt i denne
                                browser.
                            </h3>
                            <p>
                                Skift til Chrome, Safari, Firefox, Edge samt
                                Internet Explorer 11 eller nyere, så er du
                                sikker på bedre brugeroplevelse - og kan
                                downloade PDF.
                            </p>
                        </section>
                    </div>
                </div> */}
            </div>
        );
    }
}

Intro.propTypes = {
    bodyClass: PropTypes.func.isRequired
};

export default Intro;
