import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Progress } from 'semantic-ui-react';
import Lottie from 'react-lottie';

import Helpers from '../helpers';

import { ReactComponent as SVGLogo } from '../svg/logo.svg';

import Intro from './intro';
import Questions from './questions';
import Survey from './survey/survey.js';
import Complete from './complete';
import PDF from './pdf';
import Page404 from './404'

import ExtraQuestions from './extra-questions';

// if (process.env.NODE_ENV !== 'production') {
//     const { whyDidYouUpdate } = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

class Views extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            surveyData: null,
            gotData: false,
            currentColor: '',
            unitData: null,
            redirect: null,
            wrongId: false
        };

    }

    componentDidMount() {
        this.getSurveyData();
    }

    componentWillMount() {
        this.getDataFromSessionStorage();
    }

    getDataFromSessionStorage() {

        if (sessionStorage.getItem('surveyData')) {
            console.log('getting data from session');
            this.setState({
                gotData: true,
                surveyData: JSON.parse(sessionStorage.getItem('surveyData'))
            }, () => {
                console.log(this.state.surveyData);

            });
        }
    }

    getSurveyData() {

        if (sessionStorage.getItem('surveyData')) {
            return null;
        }

        let unit_id = Helpers.getUrlParameter('id');
        if (unit_id) {

            console.log('ID found. Retriving data from unit');

            var url = (Helpers.isDev()) ? 'https://cabiadmin.app/api/unit/' + unit_id : 'https://admin.cabisurvey.umano.dev/api/unit/' + unit_id;

            axios.get(url).then(res => {
                const unitData = res.data;
                this.setState({
                    unitData: unitData,
                });
                sessionStorage.setItem('unitData', JSON.stringify(unitData));

                console.log(unitData);

                // If no progress get the unit's screening type
                if (unitData.progress === 0) {
                    axios.get('/data/' + unitData.screening.type + '.json')
                        .then(res => {
                            const surveyData = res.data;
                            surveyData.isV2 = true;
                            this.setState({
                                gotData: true,
                                surveyData: surveyData,
                            }, () => {
                                this.setClientQuestions(unitData.screening);
                                this.goToProgressPageAndSlide(unitData);
                            });
                        });
                } else {
                    // If progress found then load the data into surveyData
                    let surveyData = JSON.parse(unitData.progress_data);
                    surveyData.isV2 = true;
                    this.setState({
                        gotData: true,
                        surveyData: surveyData
                    }, () => {
                        this.setClientQuestions(unitData.screening);
                        this.goToProgressPageAndSlide(unitData);
                    });
                }

            }).catch(error => {
                console.log(error.response);
                // If unit was not found load up the warning message 
                if (error.response.status === 404) {
                    this.setState({
                        wrongId: true,
                        gotData: true
                    });
                }
            });

        } else {
            axios.get('/data/default.json')
                .then(res => {
                    const surveyData = res.data;
                    this.setState({
                        gotData: true,
                        surveyData: surveyData
                    });
                });
        }


    }

    setClientQuestions(screening) {
        this.setState({
            surveyData: {
                ...this.state.surveyData,
                client_questions: {
                    client_type: {
                        ...this.state.surveyData.client_questions.client_type,
                        value: screening.client_type
                    },
                    company_size: {
                        ...this.state.surveyData.client_questions.company_size,
                        value: screening.company_size
                    },
                    industries: {
                        ...this.state.surveyData.client_questions.industries,
                        value: screening.industry
                    }
                }
            }
        })
    }


    goToProgressPageAndSlide(unitData) {

        if (unitData.progress === 0) {
            console.log('Progress is 0');

            this.setState({
                redirect: {
                    path: '/',
                    slide: null
                }
            });
            return;
        }

        let requestedSurveyRoute = '/survey/' + unitData.progress_section;

        if (window.location.pathname === requestedSurveyRoute) {
            this.setState({
                redirect: {
                    path: null,
                    slide: unitData.progress_link
                }
            });
            return;
        }

        let getSection = unitData.progress_link.split('_')[0];

        if (unitData.progress === 100) {
            console.log('Progress is 100');
            this.setState({
                redirect: {
                    path: '/complete/',
                    slide: null
                }
            });
        } else {
            this.setState({
                redirect: {
                    path: '/survey/' + getSection,
                    slide: unitData.progress_link
                }
            });
        }
    }

    componentDidUpdate(props, state) {

    }

    saveProgress() {
        if (this.state.unitData) {
            console.log('saving data');

            let requestData = {
                survey_data: JSON.stringify(this.state.surveyData)
            }

            axios({
                url: (Helpers.isDev()) ? 'https://cabiadmin.app/api/unit/' + this.state.unitData.survey_id : 'https://admin.cabisurvey.umano.dev/api/unit/' + this.state.unitData.survey_id,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                data: requestData
            }).then(response => {
                const unitData = response.data;
                this.setState({
                    unitData: unitData
                })
                console.log(unitData);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    updateClientAnswers(answers) {
        let questions = this.state.surveyData.client_questions;
        questions[answers.name].value = answers.value;

        this.setState({
            surveyData: {
                ...this.state.surveyData,
                client_questions: questions
            }
        }, () => {
            //console.log(this.state.surveyData);
            this.saveProgress();
        });
    }

    updateExtraAnswers(questions) {
        this.setState({
            surveyData: {
                ...this.state.surveyData,
                custom_questions: questions
            }
        }, () => {
            console.log(this.state.surveyData);

        });
    }

    updateAnswers(answer) {

        let sections = this.state.surveyData.sections;

        if (answer.type === 'question') {
            for (let index = 0; index < sections.length; index++) {
                const section = sections[index];
                if (section.slug === answer.section.slug) {
                    let questions = section.questions;

                    let totalScore = 0;

                    for (let questionsIndex = 0; questionsIndex < questions.length; questionsIndex++) {
                        const question = questions[questionsIndex];
                        if (question.id === answer.slide.id) {
                            question.score = answer.value;
                            section.slides[question.id].score = answer.value;
                        }
                        if (question.score && question.score !== 0) {
                            totalScore = totalScore + question.score;
                        }
                    }

                    console.log(totalScore);

                    section.totalscore = totalScore;
                    section.average = (((section.totalscore / questions.length) * 10) / 10).toFixed(1);
                    console.log(section.average);
                }
            }
        }

        if (answer.type === 'comments') {
            for (let index = 0; index < sections.length; index++) {
                const section = sections[index];
                if (section.slug === answer.section.slug) {
                    section.comments = answer.value;
                }
            }
        }

        this.setState({
            surveyData: {
                ...this.state.surveyData,
                sections: sections
            }
        }, () => {
            console.log(this.state.surveyData);
            this.saveProgress();
        });

    }

    /*
        Get process bar sections and return them
    */
    getNavigationSections() {

        if (!this.state.surveyData) {
            return null;
        }

        //console.log('progress');
        let list = (
            <React.Fragment>
                {this.state.surveyData.sections.map((section, index) => {
                    if (section.done) {
                        section.progress = 100;
                    }
                    return (
                        <a key={index} href={'/survey/' + section.slug} className={classNames({ 'active': section.active, 'done': section.done }, 'item color-' + section.color)}>
                            {section.title}
                            <Progress percent={section.progress} size='tiny' className="" />
                        </a>
                    )
                })}
            </React.Fragment>
        );

        return list;
    }

    /*
        Set the progress of the progress bar
    */
    setProgress(progress) {

        let activeSectionHasBeenFound = false;

        let sections = this.state.surveyData.sections.map((section, index) => {
            section.active = false;

            if (section.slug === progress.section.slug) {
                activeSectionHasBeenFound = true;
                section.active = true;
                section.progress = (progress.index / section.slides.length) * 100;
            }

            if (section.slug !== progress.section.slug && !activeSectionHasBeenFound) {
                section.done = true;
            }

            return section;
        });

        this.setState({
            sections: sections,
        });

    }

    setBodyClass(bodyClass) {
        document.body.className = [];
        document.body.classList.add(bodyClass);
    }

    setCurrentColor(color) {
        this.setState({
            currentColor: color
        });
    }

    /*
        Render
    */
    render() {

        //console.log(this.state.surveyData);


        let wrongId = classNames({
            'active': this.state.wrongId
        }, 'ui container fluid loader-container clickable');

        if (this.state.wrongId) {
            return (
                <React.Fragment>
                    <div className={wrongId}>
                        <div className="ui grid middle aligned">
                            <div className="center aligned column loader-animation">
                                <h1>Survey ID blev ikke fundet</h1>
                                <p>
                                    Vi kunne ikke finde den Survey du efterspurgte. <br />
                                    Tjek venligst om ID'et i URL'en er det rigtige
                                </p>
                                <p>
                                    Hvis du har lyst til at forsætte uden at dine data <br />bliver gemt, kan du bruge knappen herunder.
                                </p>
                                <p>
                                    <a href="/" className="ui button blue">
                                        Tryk her for at nulstille Survey'en til en default survey
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }


        if (!this.state.gotData) {
            return null;
        }

        //console.log('rendering view');

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: require('../svg/data/data.json'),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        let mainClasses = classNames({
            'active': !this.state.loading,
        }, 'ui container main-container fluid');

        let loadingClasses = classNames({
            'active': this.state.loading
        }, 'ui container fluid loader-container', 'color-' + this.state.currentColor);

        return (
            <React.Fragment>
                <div className={loadingClasses}>
                    <div className="ui grid middle aligned">
                        <div className="column loader-animation">
                            <Lottie options={defaultOptions}
                                height={600}
                                width={600}
                            />
                        </div>
                    </div>
                </div>

                <div className={mainClasses}>
                    <header id="navigation">
                        <div className="ui container">
                            <div className="ui text menu">
                                {this.getNavigationSections()}
                            </div>
                            <div className="logo">
                                <SVGLogo />
                            </div>
                        </div>
                    </header>
                    <section id="main">
                        <Router>
                            <Switch>
                                <Route exact path="/" render={(props) =>
                                    <Intro
                                        {...props}
                                        surveyData={this.state.surveyData}
                                        loading={(loading) => this.setState({ ...this.state, loading: loading })}
                                        bodyClass={(bodyClass) => this.setBodyClass(bodyClass)}
                                        unitData={this.state.unitData}
                                        redirect={this.state.redirect}
                                    />
                                } />

                                <Route path="/questions" render={(props) =>
                                    <Questions
                                        {...props}
                                        surveyData={this.state.surveyData}
                                        loading={(loading) => this.setState({ ...this.state, loading: loading })}
                                        bodyClass={(bodyClass) => this.setBodyClass(bodyClass)}
                                        sendAnswers={(answers) => this.updateClientAnswers(answers)}
                                        redirect={this.state.redirect}
                                    />
                                } />

                                <Route path="/survey" render={(props) =>
                                    <Survey
                                        {...props}
                                        sections={this.state.surveyData.sections}
                                        bodyClass={(bodyClass) => this.setBodyClass(bodyClass)}
                                        setProgress={(progress) => this.setProgress(progress)}
                                        answerQuestion={(answer) => this.updateAnswers(answer)}
                                        loading={(loading) => this.setState({ ...this.state, loading: loading })}
                                        setCurrentColor={(color) => this.setCurrentColor(color)}
                                        redirect={this.state.redirect}
                                        unitData={this.state.unitData}
                                    />
                                } />

                                <Route path="/extra-questions" render={(props) =>
                                    <ExtraQuestions
                                        {...props}
                                        surveyData={this.state.surveyData}
                                        loading={(loading) => this.setState({ ...this.state, loading: loading })}
                                        bodyClass={(bodyClass) => this.setBodyClass(bodyClass)}
                                        sendAnswers={(answers) => this.updateExtraAnswers(answers)}
                                        unitData={this.state.unitData}
                                    //redirect={this.state.redirect}
                                    />
                                } />

                                <Route path="/complete" render={(props) =>
                                    <Complete
                                        {...props}
                                        surveyData={this.state.surveyData}
                                        loading={(loading) => this.setState({ ...this.state, loading: loading })}
                                        bodyClass={(bodyClass) => this.setBodyClass(bodyClass)}
                                        redirect={this.state.redirect}
                                        unitData={this.state.unitData}
                                    />
                                } />

                                <Route path="/pdf" render={(props) =>
                                    <PDF
                                        {...props}
                                        surveyData={this.state.surveyData}
                                        loading={(loading) => this.setState({ ...this.state, loading: loading })}
                                        bodyClass={(bodyClass) => this.setBodyClass(bodyClass)}
                                        answers={this.state.answers}
                                        data={this.state.data}
                                    />
                                } />

                                <Route component={Page404} />
                            </Switch>
                        </Router>
                    </section>
                </div>
            </React.Fragment >
        );
    }
}

export default Views;
