import React from 'react';
import PropTypes from 'prop-types';
// import { ReactComponent as SVGArrow } from '../../../../svg/arrow.svg';
import classNames from 'classnames';

const Question = (props) => {

    const answerQuestion = (value) => {
        props.answerQuestion({
            type: 'question',
            value: value,
            section: props.section,
            slide: props.slide
        });
        setTimeout(() => {
            props.goToNextSection();
        }, 700);
    };

    return (
        <div className={props.classes} data-percentage="60" data-centered={true} data-color={props.slide.color}>
            <div className="ui container top">
                <div className="ui grid">
                    <div className="two wide column centered center aligned">
                        <span className="number">{props.index}</span>
                    </div>
                    <div className="thirteen wide column grid middle aligned">
                        <h2>{props.slide.question}</h2>
                    </div>
                </div>
            </div >
            <div className="ui container bottom">
                <div className="ui grid">
                    <div className="sixteen wide column">
                        <ul>
                            <li onClick={() => answerQuestion(1)} className={classNames({ 'active': props.slide.score === 1 })}>
                                <div className="ui grid">
                                    <div className="column tast">

                                    </div>
                                    <div className="column number grid middle aligned">
                                        <h4>1</h4>
                                    </div>
                                    <div className="column dash">
                                    </div>
                                    <div className="thirteen wide column text grid middle aligned">
                                        Ingen grad
                                        </div>
                                </div>
                            </li>
                            <li onClick={() => answerQuestion(2)} className={classNames({ 'active': props.slide.score === 2 })}>
                                <div className="ui grid">
                                    <div className="column tast">

                                    </div>
                                    <div className="column number grid middle aligned">
                                        <h4>2</h4>
                                    </div>
                                    <div className="column dash">
                                    </div>
                                    <div className="thirteen wide column text grid middle aligned">
                                        Mindre grad
                                        </div>
                                </div>
                            </li>
                            <li onClick={() => answerQuestion(3)} className={classNames({ 'active': props.slide.score === 3 })}>
                                <div className="ui grid">
                                    <div className="column tast">

                                    </div>
                                    <div className="column number grid middle aligned">
                                        <h4>3</h4>
                                    </div>
                                    <div className="column dash">
                                    </div>
                                    <div className="thirteen wide column text grid middle aligned">
                                        I nogen grad
                                        </div>
                                </div>
                            </li>
                            <li onClick={() => answerQuestion(4)} className={classNames({ 'active': props.slide.score === 4 })}>
                                <div className="ui grid">
                                    <div className="column tast">

                                    </div>
                                    <div className="column number grid middle aligned">
                                        <h4>4</h4>
                                    </div>
                                    <div className="column dash">
                                    </div>
                                    <div className="thirteen wide column text grid middle aligned">
                                        Høj grad
                                                </div>
                                </div>
                            </li>
                            <li onClick={() => answerQuestion(5)} className={classNames({ 'active': props.slide.score === 5 })}>
                                <div className="ui grid">
                                    <div className="column tast">

                                    </div>
                                    <div className="column number grid middle aligned">
                                        <h4>5</h4>
                                    </div>
                                    <div className="column dash">
                                    </div>
                                    <div className="thirteen wide column text grid middle aligned">
                                        Meget høj grad
                                        </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}




Question.propTypes = {
    index: PropTypes.number.isRequired,
    slide: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
    classes: PropTypes.string.isRequired,
    goToNextSection: PropTypes.func.isRequired,
    goToPreviousSection: PropTypes.func.isRequired,
    answerQuestion: PropTypes.func.isRequired,
};

export default Question;
