import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { ReactComponent as SVGArrow } from '../../../../svg/arrow.svg';
import { ReactComponent as SVGCross } from '../../../../svg/cross.svg';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';


class Comments extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            comments: this.props.section.comments
        };
        this.textAreas = [];

        //console.log(this.props.sectionNavigationInfo);

    }

    updateComment(currentComment, event) {

        const target = event.target;
        const value = target.value;

        let comments = this.state.comments.map((comment) => {
            if (comment.id === currentComment.id) {
                comment.value = value;
            }
            return comment;
        });

        this.setState({
            comments: comments
        }, () => {
            //console.log(comments);
        });

    }

    clearComment(currentComment, event) {

        let comments = this.state.comments.map((comment) => {
            if (comment.id === currentComment.id) {
                comment.value = '';
                this.textAreas[this.props.slide.section + '_' + currentComment.id].focus();
            }
            return comment;
        });

        this.reBuildSlide();

        this.setState({
            comments: comments
        }, () => {
            //console.log(comments);
            this.answerCommentQuestion(comments);
        });

    }

    setCommentDone(currentComment) {
        //console.log(currentComment);

        if (!currentComment.value) {
            return;
        }

        let comments = this.state.comments.map((comment) => {
            if (comment.id === currentComment.id) {
                if (comment.done === true) {
                    comment.done = false;
                    this.textAreas[this.props.slide.section + '_' + currentComment.id].focus();
                } else {
                    comment.done = true;
                }
            }
            return comment;
        });

        this.reBuildSlide();

        this.setState({
            comments: comments
        }, () => {
            //console.log(comments);
            this.answerCommentQuestion(comments);
        });

    }

    answerCommentQuestion(value) {
        this.props.answerQuestion({
            type: 'comments',
            value: value,
            section: this.props.section,
            slide: this.props.slide
        });
    }

    reBuildSlide() {
        //console.log('building');
        if (window.fullpage_api) {
            window.fullpage_api.reBuild();
        }
    }

    nextButtonOnLastSlide() {

        let unitData = JSON.parse(sessionStorage.getItem('unitData'));

        if (!this.props.sectionNavigationInfo.nextSection && unitData && unitData.screening.custom_questions.length > 0) {
            let path = {
                pathname: '/extra-questions',
                search: window.location.search
            };
            return (
                <div className="left floated column navigation-box filled">
                    <div className="wrapper">
                        <h3>Næste</h3>
                        <span>Sidste spørgsmål</span>
                        <SVGArrow />
                    </div>
                    <Link to={path}></Link>
                </div>
            )
        }

        if (!this.props.sectionNavigationInfo.nextSection) {
            let path = {
                pathname: '/complete',
                search: window.location.search
            };
            return (
                <div className="left floated column navigation-box filled">
                    <div className="wrapper">
                        <h3>Næste</h3>
                        <span>Resultat</span>
                        <SVGArrow />
                    </div>
                    <Link to={path}></Link>
                </div>
            )
        } else {
            let path = {
                pathname: '/survey/' + this.props.sectionNavigationInfo.nextSection.slug,
                search: window.location.search
            };
            return (
                <div className="left floated column navigation-box filled">
                    <div className="wrapper">
                        <h3>Næste</h3>
                        <span>{this.props.sectionNavigationInfo.nextSection.title}</span>
                        <SVGArrow />
                    </div>
                    <Link to={path}></Link>
                </div>
            )
        }
    }

    render() {

        return (
            <div className={this.props.classes} data-color={this.props.slide.color} data-centered={true}>
                <div className="scroll-wrapper">
                    <section className="top-navigation">
                        <div className="ui container">
                            <div className="ui grid centered">
                                <div className="navigation-box box-left up" onClick={() => this.props.goToPreviousSection()}>
                                    <div className="wrapper">
                                        <h3>Tilbage</h3>
                                        <span>Oversigt</span>
                                        <SVGArrow />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="ui container comments">
                        <div className="ui grid">
                            <div className="sixteen wide column">
                                {this.state.comments.map((comment, index) => (
                                    <div className="comment" key={comment.title}>
                                        <div className="header">
                                            <h3>{comment.title}</h3>
                                            <p>{comment.description}</p>
                                        </div>
                                        <div className="done textarea">

                                            <TextareaAutosize
                                                minRows={1}
                                                name={comment.id}
                                                value={comment.value}
                                                //onBlur={this.setCommentDone.bind(this, comment)}
                                                onChange={this.updateComment.bind(this, comment)}
                                                onHeightChange={this.reBuildSlide.bind(this)}
                                                readOnly={comment.done}
                                                inputRef={ref => this.textAreas[this.props.slide.section + '_' + comment.id] = ref}
                                                placeholder="Indtast din kommentar her"
                                            />

                                            {/* <Button basic onClick={this.setCommentDone.bind(this, comment)}
                                                disabled={(comment.value) ? false : true}>
                                                {(comment.done) ? 'rediger' : 'Tilføj'}
                                            </Button> */}

                                            <Popup className={this.props.slide.color} trigger={
                                                <div className="clear" onClick={this.clearComment.bind(this, comment)}>
                                                    <SVGCross />
                                                </div>
                                            } content='Nulstil kommentar?' horizontalOffset={4} />

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <section className="bottom-navigation">
                        <div className="ui container">
                            <div className="ui grid">
                                <div className="two column row">
                                    <div className="right floated column navigation-box box-left" onClick={() => this.props.goToPreviousSection()}>
                                        <div className="wrapper">
                                            <h3>Tilbage</h3>
                                            <span>Oversigt</span>
                                            <SVGArrow />
                                        </div>
                                    </div>
                                    {this.nextButtonOnLastSlide()}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

Comments.propTypes = {
    slide: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
    classes: PropTypes.string.isRequired,
    goToNextSection: PropTypes.func.isRequired,
    goToPreviousSection: PropTypes.func.isRequired,
    answerQuestion: PropTypes.func.isRequired
};

export default Comments;
