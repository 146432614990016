import React from 'react';
import { Link } from 'react-router-dom';
// import { Popup } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';


import { Radio } from 'semantic-ui-react';

// import { ReactComponent as SVGSmallArrow } from '../../svg/small_arrow.svg';
import { ReactComponent as SVGArrow } from '../../svg/arrow.svg';
import { ReactComponent as SVGDots } from '../../svg/dots2.svg';
// import { ReactComponent as SVGCross } from '../../svg/cross.svg';

//import { ReactComponent as SVGArrow } from '../../svg/arrow.svg';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

class ExtraQuestions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            questions: []
        }
    }

    shouldComponentUpdate(props, state) {

        if (props.redirect === this.props.redirect) {
            return true;
        }

        if (props.redirect) {
            console.log('redirecting ' + props.redirect);

            if (props.redirect.path) {
                props.history.push({
                    pathname: props.redirect.path,
                    search: props.location.search
                });
            }

            setTimeout(() => {
                if (window.fullpage_api) {
                    window.fullpage_api.moveTo(props.redirect.slide);
                }
            }, 500);

            return false;
        }

        return true;
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.loading(false);
        }, 1000);
        this.props.bodyClass('extra-questions');

        let surveyData = JSON.parse(sessionStorage.getItem('surveyData'));

        if (surveyData && surveyData.custom_questions) {
            this.setState({
                questions: surveyData.custom_questions
            });
        } else {
            let unitData = JSON.parse(sessionStorage.getItem('unitData'));

            this.setState({
                questions: unitData.screening.custom_questions
            });
        }



    }

    componentWillMount() {
        this.props.loading(true);
    }

    updateQuestions(currentQuestion, event) {

        const target = event.target;
        const value = target.type === 'radio' ? target.checked : target.value;

        let questions = this.state.questions.map((question) => {
            if (question.id === currentQuestion.id) {
                question.value = value;
            }
            return question;
        });

        this.setState({
            questions: questions
        }, () => {
            this.props.sendAnswers(this.state.questions);
        });

    }

    handleChangeCheck(event, props) {

        let questions = this.state.questions.map((question) => {
            if (question.id === props['data-id']) {
                question.value = props.value;
            }
            return question;
        });

        this.setState({
            questions: questions
        }, () => {
            this.props.sendAnswers(this.state.questions);
        });

    }

    renderQuestion(question) {

        if (question.type === 'comment') {
            let value = (question.value) ? question.value : '';
            return (
                <div className="done textarea">
                    <header>
                        <h3>{question.text}</h3>
                    </header>
                    <TextareaAutosize
                        minRows={1}
                        name={question.id}
                        value={value}
                        onChange={this.updateQuestions.bind(this, question)}
                        placeholder="Indtast din kommentar her"
                    />

                </div>
            );
        } else {
            return (
                <div className="value_range">

                    <header>
                        <h3>{question.text}</h3>
                    </header>

                    <Radio
                        label='1: Ingen grad'
                        name='checkboxRadioGroup'
                        value='1'
                        data-id={question.id}
                        checked={question.value === '1'}
                        onChange={this.handleChangeCheck.bind(this)}
                    />

                    <Radio
                        label='2: Mindre grad'
                        name='checkboxRadioGroup'
                        value='2'
                        data-id={question.id}
                        checked={question.value === '2'}
                        onChange={this.handleChangeCheck.bind(this)}
                    />

                    <Radio
                        label='3: I nogen grad'
                        name='checkboxRadioGroup'
                        value='3'
                        data-id={question.id}
                        checked={question.value === '3'}
                        onChange={this.handleChangeCheck.bind(this)}
                    />

                    <Radio
                        label='4: Høj grad'
                        name='checkboxRadioGroup'
                        value='4'
                        data-id={question.id}
                        checked={question.value === '4'}
                        onChange={this.handleChangeCheck.bind(this)}
                    />

                    <Radio
                        label='5: Meget høj grad'
                        name='checkboxRadioGroup'
                        value='5'
                        data-id={question.id}
                        checked={question.value === '5'}
                        onChange={this.handleChangeCheck.bind(this)}
                    />


                </div>
            );
        }

    }

    render() {
        console.log(this.props);

        let path = {
            pathname: '/complete/',
            search: window.location.search
        };

        if (!this.state.questions) {
            return null;
        }

        return (

            <div className="ui container questions">
                <div className="dots">
                    <SVGDots />
                </div>
                <div className="ui middle aligned centered aligned one column grid">
                    <div className="row">
                        <div className="fourteen wide column questions-wrapper">
                            <div className="content">
                                <h1>Og så lige til sidst...</h1>

                                {this.state.questions.map((question) =>


                                    <div className="question" key={question.id}>
                                        {this.renderQuestion(question)}
                                    </div>

                                )}

                                <div className="link-wrapper">
                                    <Link to={path}>
                                        <div className="action ui grid">
                                            <div className="ui eight wide column right aligned middle aligned">
                                                <h3>Næste</h3>
                                            </div>
                                            <div className="ui eight wide column">
                                                <SVGArrow />
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

ExtraQuestions.propTypes = {
    bodyClass: PropTypes.func.isRequired,
    surveyData: PropTypes.object.isRequired,
    sendAnswers: PropTypes.func.isRequired
};

export default ExtraQuestions